import React from 'react';
import { MinPageHeight, Container } from '../styles/globalStyles';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Sidenote from '../components/Sidenote';
import styled from '@emotion/styled';
import { Colors } from '../styles/globalStyles';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

const HackerNewsSection = styled.section`
  a {
    color: ${Colors.red};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      display: grid;
      grid-template-columns: 1fr 0;
      grid-template-columns: 100%;
      @media (min-width: 768px) {
        grid-template-columns: 80% 20%;
      }
    }
  }

  .hacker-news-post-date {
    display: none;
    text-align: right;
    @media (min-width: 768px) {
      display: block;
    }
  }
`;

const HackerNews = ({ entries }) => {
  return (
    <Layout>
      <Seo
        title="Hacker News - Vinay Raghu - Software Developer"
        keywords={[`gatsby`, `application`, `react`]}
      />
      <MinPageHeight>
        <Container>
          <h1>Hacker news</h1>
          <Sidenote title="👋 Welcome">
            I read hacker news a lot. No, seriously. A lot. Several times a day.
            I enjoy the conversations and the quality of links that bubble to
            the top. I usually lurk because I am not a huge fan of online
            discourse in general (hence no comments section on this site).
            However, I do have lots of thoughts and opinions around what I read.
            I started jotting these thoughts down for reference, but also to get
            these thoughts out of my head.
          </Sidenote>
          <HackerNewsSection>
            <ul>
              {entries.map((entry, index) => {
                const item = entry.node.data;
                const {
                  Title: title,
                  URL: url,
                  // Hacker_News_URL: hackerNewsUrl,
                  // Tags: tags,
                  // Notes: notes,
                  Created_at: timestamp,
                } = item;
                return (
                  <li key={index}>
                    <div>
                      <a href={url}>{title}</a>
                    </div>
                    <div className="hacker-news-post-date">
                      {formatDistanceToNow(new Date(timestamp))} ago
                    </div>
                  </li>
                );
              })}
            </ul>
          </HackerNewsSection>
        </Container>
      </MinPageHeight>
    </Layout>
  );
};

const HackerNewsDataQuery = () => (
  <StaticQuery
    query={graphql`
      {
        allAirtable(
          filter: { table: { eq: "Hacker news" } }
          sort: { fields: data___Created_at, order: DESC }
        ) {
          edges {
            node {
              data {
                Title
                URL
                Hacker_News_URL
                Tags
                Notes
                Created_at
              }
            }
          }
        }
      }
    `}
    render={(data) => <HackerNews entries={data.allAirtable.edges} />}
  />
);

export default HackerNewsDataQuery;
